body {
    padding: 0;
    margin: 0;
}

.testoLocation {
    font-family: "Poppins Regular";
}

.headertext {
    font-size: 3.5em;
    text-align: center;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-family: "Poppins Medium";
}

.arrows {
    height: 6vh;
    width: 5vw;
}

.leftArrow {
    margin-right: 20px;
}

.slick-dots.visible {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #464646;
    bottom: -1rem;
}
.slick-dots li button:before {
    color: #000;
    font-size: 1.2rem;
}

.slick-dots li.slick-active button:before {
    color: #00b2e8;
    font-size: 1.5rem;
}

.contentPara {
    font-family: "Poppins Regular";
}

.bottomtext {
    font-family: "Poppins Regular";
}

@media (max-width: 600px) {
    .arrows {
        height: 28px;
        width: 28px;
    }

    .leftArrow {
        margin-right: 20px;
    }
}
