.layoutContainer {
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  border: none;
}
.navFilter {
  display: none;
  height: 4rem;
  width: 100%;
  position: relative;
}
.header {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: inherit;
  padding: 0rem 7.5%;
  z-index: 10;
  overflow: hidden;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 0.5%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.logo:hover {
  cursor: pointer;
}
.logo img {
  object-fit: contain;
  width: 22%;
}
.navbar1 {
  width: 50%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 4%;
  height: 100%;
  z-index: 20;
}
.navbar1 li {
  list-style-type: none;
}
.navbar1 nav {
  width: 100%;
}
nav ul {
  display: flex;
  justify-content: space-evenly;
  font-size: 1.5rem;
  font-family: Ubuntu;
}
nav a {
  text-decoration: none;
  padding-bottom: 0.3rem;
}
nav a:hover {
  cursor: pointer;
}
.navbar2 {
  display: none;
  z-index: 20;
  margin: auto 0;
}
.navbar2 span {
  display: block;
  width: 24px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
  transition: 0.4s;
}
.navbar2.change span:nth-child(1) {
  transform: translate(0, 7.5px) rotate(-45deg);
}
.navbar2.change span:nth-child(2) {
  opacity: 0;
}
.navbar2.change span:nth-child(3) {
  transform: translate(0, -7px) rotate(45deg);
}
.sidebar {
  padding: 0% 4%;
  position: fixed;
  display: flex;
  flex-direction: column;
  opacity: 1;
  height: 0rem;
  z-index: 10;
  width: inherit;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  top: 5rem;
  left: 0;
  right: 0;
  transition: height 400ms linear;
  box-shadow: 0px 4px 66px rgba(109, 109, 109, 0.12) inset;
}
.sidebar nav {
  margin-top: 8rem;
}
.sidebar ul {
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu";
  font-size: 2.3rem;
  color: #464646;
  width: inherit;
}
.sidebar li {
  list-style-type: none;
  margin: 3rem 0rem;
}
.lineMark {
  margin-left: -4%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 3rem;
}
.lineMark span {
  position: absolute;
  background-color: #2e2f2f;
  height: 3rem;
  width: 50%;
  height: 5px;
  border-radius: 10px;
}
@media (max-width: 720px) {
  .navbar1 {
    display: none;
  }
  .navbar2 {
    display: block;
  }
  .header {
    padding: 0rem 4%;
    height: 5rem;
  }
  .logo img {
    object-fit: contain;
    width: 18%;
  }
}
