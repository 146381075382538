.testimonialContainer {
    height: 85vh;

    .headertext {
        margin-top: 17vh;
    }
    .slider {
        margin-top: 3vh;
    }
    .arrowsDiv {
        margin-top: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 600px) {
        height: 42vh;
        margin-bottom: -20vh;
        .headertext {
            margin-top: 5vh;
        }
        .arrowsDiv {
            margin-top: 3vh;
        }

        .slider {
            margin-top: 3vh;
        }
    }
}
