.testocardContainer {
    width: 62vw;
    height: 40vh;
    border-radius: 3.6em;
    background: linear-gradient(111deg, #0185c9, #00c4ff);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    margin-top: 4vh;

    .testoimgContainer {
        width: 30%;
        height: 110%;
        position: absolute;
        left: 0;
        margin-top: -5vh;

        #testoimg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .testocontentContainer {
        width: 70%;
        height: 100%;
        position: absolute;
        right: 2%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        z-index: 3;
        align-items: center;

        .paraDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .downComma {
                position: absolute;
                left: -1%;
                top: -20%;
            }

            .contentPara {
                color: white;
                width: 85%;
                font-size: 1.5em;
                line-height: 25px;
                text-align: center;
            }

            .upComma {
                position: absolute;
                right: 1%;
                bottom: -17%;
            }
        }

        .bottomtext {
            font-size: 1.3em;
            color: white;
            position: relative;
            right: -11%;
            top: 10%;

            .testoLocation {
                font-size: 0.7em;
            }
        }
    }

    @media (max-width: 700px) {
        width: 95vw;
        height: 23vh;
        border-radius: 9px;
        margin-top: 4vh;

        .testoimgContainer {
            margin-top: 0vh;
        }
        .testocontentContainer {
            .paraDiv {
                width: 100%;
                .downComma {
                    position: absolute;
                    left: -5%;
                    top: -20%;
                }

                .contentPara {
                    width: 90%;
                    font-size: 11px;
                    line-height: 17px;
                }

                .upComma {
                    right: -1%;
                    bottom: -17%;
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .testocontentContainer {
            .paraDiv {
                .contentPara {
                    line-height: 30px;
                }
            }
        }
    }
    @media (min-width: 1500px) {
        .testocontentContainer {
            .paraDiv {
                .contentPara {
                    line-height: 45px;
                }
            }
        }
    }
}
