.vibeWithUsSequencer {
  height: 30vh !important;
  margin: 20vh 0 0 0;
}
.vibeWithUsSequencer span {
  width: fit-content;
}

@media (max-width: 600px) {
  .vibeWithUsSequencer {
    height: 15vh !important;
    width: 100vw !important;
    margin: 0 auto;
    margin-top: 27vh;
  }
  .vibeWithUsSequencer span {
    width: fit-content;
  }
}
