.wave-svg {
    transform: rotateY(180deg);
    position: relative;
    top: -14vh;
    z-index: 3;
}
.dumyDiv {
    background-color: white;
    width: 100vw;
    z-index: 3;
    height: 11vh;
    position: relative;
    top: 10vh;
}

.zinkuContainer {
    height: 100vh;
    width: 100vw;
}

.zinContainer {
    overflow: hidden;
    background-color: #272727;
    height: 100vh;
    width: 100vw;
    position: relative;
}

.zinkusContainer {
    height: 70vh;
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40vh;
    right: 10vw;
}

.zinkuImg {
    width: 20vw;
    height: 20vw;
    position: absolute;
}

.radar1 {
    width: 27vw;
    height: 27vw;
    border: 3px solid #ff8900;
    border-radius: 50%;
    position: absolute;
}
.radar2 {
    width: 37vw;
    height: 37vw;
    border: 3px solid #ff8900;
    border-radius: 50%;
    position: absolute;
}

.radar3 {
    height: 50vw;
    width: 50vw;
    border: 3px solid #ff8900;
    border-radius: 50%;
    position: absolute;
    opacity: 64%;
}

.radar4 {
    height: 65vw;
    width: 65vw;
    border: 3px solid #ff8900;
    border-radius: 50%;
    position: absolute;
    opacity: 40%;
}

.coin1 {
    width: 20vw;
    height: 20vw;
    position: absolute;
    top: -45vh;
    z-index: 4;
}
.coin2 {
    width: 18vw;
    height: 18vw;
    position: absolute;
    top: -10vh;
    right: 5vw;
    z-index: 1;
}
.coin3 {
    width: 16vw;
    height: 16vw;
    position: absolute;
    top: -5vh;
    left: 7vw;
    z-index: 1;
    transform: rotate(27deg);
}
.coin4 {
    transform: rotate(27deg);
    width: 15vw;
    height: 15vw;
    position: absolute;
    right: 1vw;
    bottom: 7vh;
    z-index: 1;
}

.contentContainer {
    z-index: 8;
    width: 50%;
    height: 80%;
    position: absolute;
    right: 10vw;
    top: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-gradient {
    background: linear-gradient(94deg, #ffa25a, #d96300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.headerText {
    font-size: 40px;
    text-align: center;
    font-family: "Blenny";
}

.paraText {
    color: white;
    font-family: "Poppins Regular";
    font-size: 27px;
    line-height: 40px;
    text-align: center;
}

.anonymously-container {
    margin-top: 20px;
}

.anonymously {
    position: relative;
    margin-left: 5px;
}

.image {
    position: absolute;
    bottom: 0px;
    right: -6px;
    height: 38px;
    width: 195px;
}

.stepsContainer {
    margin-top: 15px;
    height: 60%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 15%;
}

.steps {
    height: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.numContainer {
    background: linear-gradient(138deg, #ffa25a, #fc994a, #f27e1f, #d96300);
    height: 60%;
    width: 20%;
    border-radius: 15px;
    box-shadow: 0 3px 12px 0.2px #e26300ad;
    font-size: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Poppins Bold";
    margin-right: 40px;
}

.stepText {
    font-family: "Poppins Regular";
    color: white;
    font-size: 25px;
    text-align: left;
    line-height: 34px;
}

.firstBrake {
    display: block;
}

.secondBrake {
    display: none;
}

@media screen and (max-width: 600px) {
    .zinkuContainer {
        height: 45vh;
        width: 100vw;
        margin-top: 1vh;
    }

    .zinContainer {
        height: 45vh;
        width: 100vw;
    }

    .zinkusContainer {
        height: 50vh;
        width: 50%;
        top: 10vh;
        right: 9vw;
    }

    .contentContainer {
        right: 3vw;
        top: 5vh;
        width: 75%;
    }

    .wave-svg {
        top: -3.6vh;
    }
    .firstBrake {
        display: none;
    }
    .secondBrake {
        display: block;
    }
    .headerText {
        font-size: 22px;
        width: 100%;
    }
    .dumyDiv {
        display: none;
    }

    .paraText {
        font-size: 10px;
        line-height: 10px;
        margin-top: -20px;
    }

    .anonymously-container {
        margin-top: 20px;
    }

    .image {
        height: 15px;
        width: 80px;
    }

    .stepsContainer {
        height: 80%;
        width: 90%;
        justify-content: center;
        align-items: center;
        margin-top: -10px;
        margin-left: 40%;
    }

    .steps {
        margin-top: 15px;
        height: 20%;
    }

    .numContainer {
        height: 95%;
        width: 17%;
        border-radius: 9px;
        font-size: 21px;
        margin-right: 20px;
    }

    .stepText {
        font-size: 10px;
        line-height: 15px;
    }

    .zinkuImg {
        width: 27vw;
        height: 27vw;
    }

    .radar1 {
        width: 33vw;
        height: 33vw;
        border: 1px solid #ff8900;
    }
    .radar2 {
        width: 47vw;
        height: 47vw;
        border: 1px solid #ff8900;
    }

    .radar3 {
        height: 65vw;
        width: 65vw;
        border: 1px solid #ff8900;
    }

    .radar4 {
        height: 80vw;
        width: 80vw;
        border: 1px solid #ff8900;
    }
    .coin1 {
        top: 2vh;
        transform: rotate(18deg);
    }
    .coin2 {
        top: 13vh;
        right: 0vw;
        transform: rotate(332deg);
    }
    .coin3 {
        top: 13vh;
        left: 4vw;
        transform: rotate(75deg);
    }
    .coin4 {
        right: 2vw;
        bottom: 14vh;
        transform: rotate(18deg);
        z-index: 4;
    }
}

@media screen and (min-width: 601px) {
    .contentContainer {
        right: 7vw;
        top: 15vh;
    }

    .zinkusContainer {
        top: 40vh;
        right: 9vw;
    }

    .wave-svg {
        top: -12vh;
    }

    .headerText {
        font-size: 3em;
    }

    .paraText {
        font-size: 2.5rem;
        line-height: 20px;
    }

    .anonymously-container {
        margin-top: 20px;
    }

    .image {
        height: 35px;
        width: 180px;
    }

    .stepsContainer {
        height: 60%;
        width: 60%;
        justify-content: center;
        align-items: center;
    }

    .steps {
        height: 30%;
    }

    .numContainer {
        height: 55%;
        width: 20%;
        border-radius: 15px;
        font-size: 27px;
        margin-right: 30px;
    }

    .stepText {
        font-size: 10px;
        line-height: 17px;
    }

    .coin1 {
        top: -40vh;
    }
    .coin2 {
        top: -10vh;
        right: 5vw;
    }
    .coin3 {
        top: -5vh;
        left: 7vw;
    }
    .coin4 {
        right: 1vw;
        bottom: 8vh;
        z-index: 4;
    }
}

@media screen and (min-width: 1024px) {
    .contentContainer {
        right: 7vw;
        top: 15vh;
    }

    .zinkusContainer {
        top: 40vh;
        right: 9vw;
    }

    .wave-svg {
        top: -13vh;
    }

    .headerText {
        font-size: 3.2em;
    }

    .paraText {
        font-size: 2.7rem;
        line-height: 20px;
    }

    .anonymously-container {
        margin-top: 20px;
    }

    .image {
        height: 43px;
        width: 240px;
    }

    .stepsContainer {
        height: 60%;
        width: 65%;
        justify-content: center;
        align-items: center;
    }

    .steps {
        height: 35%;
    }

    .numContainer {
        height: 60%;
        width: 14%;
        border-radius: 15px;
        font-size: 27px;
        margin-right: 35px;
    }

    .stepText {
        font-size: 15px;
        line-height: 23px;
    }

    .coin1 {
        top: -43vh;
    }
    .coin2 {
        top: -10vh;
        right: 4vw;
    }
    .coin3 {
        top: -5vh;
        left: 5vw;
    }
    .coin4 {
        right: 1vw;
        bottom: 4vh;
        z-index: 4;
    }
}

@media screen and (min-width: 1280px) {
    .contentContainer {
        right: 7vw;
        top: 15vh;
    }

    .zinkusContainer {
        top: 38vh;
        right: 9vw;
    }

    .wave-svg {
        top: -14vh;
    }

    .headerText {
        font-size: 3.2em;
    }

    .paraText {
        font-size: 3rem;
        line-height: 25px;
    }

    .anonymously-container {
        margin-top: 20px;
    }

    .image {
        height: 50px;
        width: 250px;
    }

    .stepsContainer {
        height: 60%;
        width: 65%;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
    }

    .steps {
        height: 35%;
    }

    .numContainer {
        height: 55%;
        width: 18%;
        border-radius: 15px;
        font-size: 35px;
        margin-right: 40px;
    }

    .stepText {
        font-size: 20px;
        line-height: 30px;
    }

    .coin1 {
        top: -42vh;
    }
    .coin2 {
        top: -10vh;
        right: 5vw;
    }
    .coin3 {
        top: -5vh;
        left: 5vw;
    }
    .coin4 {
        right: 1vw;
        bottom: 4vh;
        z-index: 4;
    }
}

@media screen and (min-width: 1400px) {
    .contentContainer {
        right: 7vw;
        top: 15vh;
    }

    .zinkusContainer {
        top: 42vh;
        right: 10vw;
    }

    .wave-svg {
        top: -12vh;
    }

    .headerText {
        font-size: 3.2em;
    }

    .paraText {
        font-size: 2.7rem;
        line-height: 25px;
    }

    .anonymously-container {
        margin-top: 20px;
    }

    .image {
        height: 55px;
        width: 305px;
    }

    .stepsContainer {
        height: 60%;
        width: 65%;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
    }

    .steps {
        height: 35%;
    }

    .numContainer {
        height: 55%;
        width: 18%;
        border-radius: 15px;
        font-size: 35px;
        margin-right: 40px;
    }

    .stepText {
        font-size: 20px;
        line-height: 30px;
    }

    .radar4 {
        width: 65vw;
        height: 65vw;
    }

    .coin1 {
        top: -40vh;
    }
    .coin2 {
        top: -10vh;
        right: 5vw;
    }
    .coin3 {
        top: -5vh;
        left: 7vw;
    }
    .coin4 {
        right: 1vw;
        bottom: 6vh;
        z-index: 4;
    }
}

@media screen and (min-width: 1700px) {
    .contentContainer {
        right: 1vw;
        top: 15vh;
        width: 60%;
    }

    .zinkusContainer {
        top: 40vh;
        right: 9vw;
    }

    .headerText {
        font-size: 3.6em;
    }

    .paraText {
        font-size: 3.5rem;
        line-height: 35px;
    }

    .anonymously-container {
        margin-top: 20px;
    }

    .image {
        height: 75px;
        width: 390px;
    }

    .stepsContainer {
        height: 60%;
        width: 65%;
        justify-content: center;
        align-items: center;
    }

    .steps {
        height: 35%;
    }

    .numContainer {
        height: 60%;
        width: 14%;
        border-radius: 15px;
        font-size: 43px;
        margin-right: 40px;
    }

    .stepText {
        font-size: 25px;
        line-height: 38px;
    }
    .coin1 {
        top: -40vh;
    }
    .coin2 {
        top: -10vh;
        right: 5vw;
    }
    .coin3 {
        top: -5vh;
        left: 5vw;
    }
    .coin4 {
        right: -1vw;
        bottom: 5vh;
        z-index: 4;
    }
}
