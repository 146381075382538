/** Generated by FG **/
@font-face {
  font-family: "Conv_PinkyPromise";
  src: url("fonts/PinkyPromise.eot");
  src: local("☺"), url("fonts/PinkyPromise.woff") format("woff"),
    url("fonts/PinkyPromise.ttf") format("truetype"),
    url("fonts/PinkyPromise.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Spirax";
  src: url("./fonts/Spirax-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Titillium";
  src: url("./fonts/TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu-bold";
  src: url("./fonts/Ubuntu-M.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu-medium";
  src: url("./fonts/Ubuntu-C.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu-light";
  src: url("./fonts/Ubuntu-L.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Ubuntu Bold";
  src: url("./fonts/Ubuntu-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts//Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins Bold";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Blenny";
  src: url("./fonts/FontsFree-Net-Blenny-W01-Black.ttf") format("truetype");
}