.zinkuSectionContainer {
  min-height: 120vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 2vw;
}

.zinkuItem {
  height: 50vh;
  width: auto;
  position: absolute;
  z-index: 2;
  left: calc(50vw - 25vh);
  top: 25vh;
}

.topSectionTitle {
  position: relative;
  z-index: 6;
  color: white;
  font-family: "Ubuntu Bold";
}

.topSectionpara {
  position: relative;
  z-index: 6;
  color: white;
  font-family: "Poppins Medium";
}

.topsecParaBreak {
  display: none;
}

.playstoreLogo {
  margin-right: 50px;
}

.logoImg {
  height: 50px;
}
.topSectionLogos {
  z-index: 6;
  color: white;
  align-items: center;
  justify-content: center;
}

.topsectionparaInside {
  background-color: rgba(255, 255, 255, 0.3);
  margin-left: 3px;
  border-radius: 10px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.zinkuItem img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.zinkuBackground {
  width: 3vw;
  height: 1vh;
  position: absolute;
  z-index: 3;
  left: 48.5vw;
  top: 49.5vh;
  background-color: #272727;
}

.zinkuRadar {
  position: absolute;
  left: calc(50vw - 670px);
  top: calc(50vh - 670px);
  z-index: 6;
}

.afterContainer {
  height: 100vh;
  width: 100vw;
  background-color: black;
  position: absolute;
}

.zinkuText {
  position: absolute;
  z-index: 3;
  width: 50vw;
  left: 45vw;
  top: 30vh;
  padding: 30px;
  color: white;
  font-size: 46px;
  font-weight: bold;
  font-family: "ubuntu-bold";
  text-align: center;
}

.topSectionContainer {
  min-height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.spotlightImg {
  position: absolute;
  z-index: 3;
  height: 100vh;
  width: 100vw;
}

.blurGlass {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 4;
  backdrop-filter: blur(50px);
  background: rgba(255, 255, 255, 0.1);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .blurGlass {
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

.allMobileContainer {
  min-height: 80vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

#allMobiletext {
  padding-top: 5vh;
  color: #707070;
  font-size: 46px;
  font-family: "Poppins Medium";
  width: 70vw;
  text-align: center;
  overflow: hidden;
  margin-bottom: 15vh;
}

.mobileCards {
  height: 25vh;
  overflow: hidden;
}

.fullScreen {
  position: absolute;
}

.logoHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logImage {
  height: 15vh;
}

.carouselCard {
  height: 100%;
  margin-right: 15px;
}

.carouselImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.corouselContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.corousel-div-1 {
  height: 25%;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  overflow-y: hidden;
}

.corousel-div-2 {
  height: 39%;
  width: 100%;
  display: flex;
  overflow-y: hidden;
  z-index: 4;
}

.corousel-text {
  background: linear-gradient(117deg, #0185c9, #00c4ff);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 8;
  font-family: "Poppins SemiBold";
}

.corouselParentDiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.corouselCategory1 {
  width: 14%;
}

.corouselCategory2 {
  width: 10%;
}

.corouselCategory3 {
  width: 18%;
}

.corouselCategory4 {
  width: 9%;
}

.corouselCategory5 {
  width: 7%;
}

.corouselCategory6 {
  width: 16%;
}

.corouselCategory7 {
  width: 20%;
}

.corouselCategory8 {
  width: 9%;
}

.corouselCategory9 {
  width: 12%;
}
.corouselCategory10 {
  width: 18%;
}
.corouselCategory11 {
  width: 12%;
}

.carouselWrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin-bottom: 50px;
  margin-top: -15vh;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.carouselWrapper::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 600px) {
  .corouselContainer {
    width: 2700px;
    height: 100%;
  }
  .carouselWrapper {
    height: 70vh;
    margin-top: -8vh;
    margin-bottom: 10vh;
  }
  .carouselCard,
  .carouselImg {
    border-radius: 8px;
  }
  .text1 {
    font-size: 16px;
  }
  .text2 {
    font-size: 18px;
  }
  .text3 {
    font-size: 18px;
  }
  .corousel-div-1 {
    justify-content: unset;
  }
  .carouselCard {
    margin-right: 10px;
  }
}

@media screen and (min-width: 601px) {
  .corouselContainer {
    width: 2600px;
    height: 85%;
  }
  .carouselWrapper {
    height: 50vh;
    margin-top: 10vh;
  }
  .carouselCard,
  .carouselImg {
    border-radius: 8px;
  }
  .text1 {
    font-size: 16px;
  }
  .text2 {
    font-size: 18px;
  }
  .text3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .corouselContainer {
    width: 2000px;
    height: 80%;
  }
  .carouselWrapper {
    margin-top: -7vh;
    height: 100vh;
  }
  .carouselCard,
  .carouselImg {
    border-radius: 20px;
  }
  .text1 {
    font-size: 16px;
  }
  .text2 {
    font-size: 18px;
  }
  .text3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 1280px) {
  .corouselContainer {
    width: 2300px;
    height: 90%;
  }
  .text1 {
    font-size: 20px;
  }
  .text2 {
    font-size: 25px;
  }
  .text3 {
    font-size: 25px;
  }
}

@media screen and (min-width: 1536px) {
  .corouselContainer {
    width: 4700px;
    height: 100%;
  }
  .text1 {
    font-size: 40px;
  }
  .text2 {
    font-size: 50px;
  }
  .text3 {
    font-size: 40px;
  }

  #allMobiletext {
    padding-top: 5vh;
    font-size: 46px;
  }
}

@media (max-width: 600px) {
  .logImage {
    height: 9vh;
  }
  .allMobileContainer {
    margin-top: -3vh;
  }
  #allMobiletext {
    font-size: 30px;
  }
  .zinkuItem {
    width: 75vw;
    height: auto;
    left: 12.5vw;
    top: calc(50vh - 37.5vw);
  }
  .zinkuRadar {
    width: auto;
    height: 100vh;
    left: 0;
    top: 0;
  }
  .zinkuRadar img {
    width: 140%;
    height: 64%;
    object-fit: cover;
  }
  .zinkuText {
    font-size: 20px;
    top: 10vh;
    width: 90vw;
    left: 5vw;
  }
  .topSectionTitle {
    font-size: 15px;
  }
  .topSectionpara {
    font-size: 12px;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 9px;
    text-align: center;
    margin-top: -20px;
  }
  .topsecParaBreak {
    display: block;
  }
  .logoImg {
    height: 30px;
  }
  .playstoreLogo {
    margin-right: 30px;
  }
  .topSectionContainer {
    min-height: 90vh;
  }
}
